<template>
  <el-scrollbar class="exam-content">
    <div class="live-data">
      
      <div class="operate">
         <el-button style="float: left" @click="back"  class="btn add-btn" >返回</el-button>
        <img :src="require('../../assets/image/title.png')" />
        <span style="vertical-align: middle; font-size: 26px; font-weight: bold"
          >直播复盘数据</span
        >
        <img
          class="title-right"
          :src="require('../../assets/image/title.png')"
        />
      </div>
      <div class="title-item">
        <div class="title-one">
          <div class="cont">
            <span class="title-num">{{ this.in_online_number }}</span>
            <p class="title-text">累计观看人数</p>
          </div>
        </div>
        <div class="title-two">
          <div class="cont">
            <span class="title-num">{{
              this.highest_online_total_number
            }}</span>
            <p class="title-text">人数峰值</p>
          </div>
        </div>
        <div class="title-three">
          <div class="cont">
            <span class="title-num">{{ this.ave_online_total_number }}</span>
            <p class="title-text">平均在线</p>
          </div>
        </div>
        <div class="title-four">
          <div class="cont">
            <span class="title-num">{{ this.deal_number }}</span>
            <p class="title-text">本场销量</p>
          </div>
        </div>
        <div class="title-five">
          <div class="cont">
            <span class="title-num">{{ this.sales }}</span>
            <p class="title-text">本场销售额</p>
          </div>
        </div>
      </div>
      <div class="dataTitle">
        <ul>
          <div style="display: flex;background-color: #1d1f4f;border-radius: 30px">
          <li  :class="{dataTitle_active: currentIndex ==index}" @click='dataTitleFun(index)' v-for="(item,index) in dataTitleList" :key="index">{{item.name}}</li>
          </div>
        </ul>
      </div>
      <div class="title-item-two">
        <div v-show="synthesize" class="item-left">
          <!-- <div style="padding: 10px 15px 0 15px">
            <img
              style="padding-right: 10px"
              class="title-right"
              :src="require('../../../assets/image/tag.png')"
            /><span>人气带货数据</span>
          </div> -->
          <div id="sellGoods" style="width: 100%; height: 100%"></div>
        </div>
        <div v-show="interaction" class="item-right">
          <div
            style="
              padding: 10px 15px 0 15px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-wrap: wrap;
              float: right;
            "
          >
            <div>
              <!-- <img
                style="padding-right: 10px"
                class="title-right"
                :src="require('../../../assets/image/tag.png')"
              /><span>观众互动趋势图</span> -->
            </div>
            <div>
              <span style="padding-right: 10px">总弹幕:</span
              ><span
                style="
                  font-size: 20px;
                  font-weight: bold;
                  color: #4b45ff;
                  padding-right: 20px;
                "
                >{{barrage_num}}</span
              >
              <span style="padding-right: 10px">总点赞:</span
              ><span style="font-size: 20px; font-weight: bold; color: #12c4c4"
                >{{like_num}}</span
              >
            </div>
          </div>
          <div id="tendency" style="width: 100%; height: 100%"></div>
        </div>
        <div v-show="temperature" class="item-left" style="position: relative">
          <div
            style="
              padding: 10px 15px 0 15px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-wrap: wrap;
              position: absolute;
              right: 0;
              z-index: 9999;
            "
          >
            <!-- <div>
              <img
                style="padding-right: 10px"
                class="title-right"
                :src="require('../../../assets/image/tag.png')"
              /><span>商品热度趋势</span>
            </div> -->
            <div style="clear:both">
              <el-select
                v-model="commodity"
                placeholder="请选择商品"
                @change="toggleClass()"
                :popper-append-to-body="false"
                size="mini"
              >
                <el-option
                  v-for="item in commodityList"
                  :key="item.goods_id"
                  :label="item.goods_name"
                  :value="item.goods_id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div id="heat" style="width: 100%; height: 100%;"></div>
        </div>
        <!-- <div class="item-right">
          <div style="padding: 10px 15px 0 15px">
            <img
              style="padding-right: 10px"
              class="title-right"
              :src="require('../../../assets/image/tag.png')"
            /><span>销售额</span>
          </div>
          <div id="sale" style="width: 100%; height: 100%"></div>
        </div> -->
      </div>
      <div class="title-item-foot">
        <div class="item-foot-left">
          <div style="height: 240px; background: #0c0e3f">
            <div style="padding: 10px 15px 0 15px">
              <img
                style="padding-right: 10px"
                class="title-right"
                :src="require('../../assets/image/tag.png')"
              /><span>性别分布</span>
            </div>
            <div class="audience-source-sex">
              <div class="man-box">
                <el-progress
                  type="circle"
                  :percentage="sex_mam"
                  :strokeWidth="14"
                  color="rgb(75,69,255)"
                  :width="100"
                  style="margin-left: 50px"
                  class="man"
                >
                </el-progress>
                <span
                  class="iconfont icon-nan"
                  style="font-size: 28px; color: #727599"
                  >&#xe710;</span
                >
              </div>
              <div class="female-box">
                <el-progress
                  type="circle"
                  :percentage="sex_woman"
                  :strokeWidth="14"
                  color="rgb(255,62,108)"
                  :width="100"
                  style="margin-left: 74px"
                  class="female"
                ></el-progress>
                <span
                  class="iconfont icon-nv"
                  style="font-size: 28px; color: #727599"
                  >&#xe644;</span
                >
              </div>
            </div>
          </div>
          <div style="height: 425px; background: #0c0e3f; margin-top: 25px">
            <div style="padding: 10px 15px 0 15px">
              <img
                style="padding-right: 10px"
                class="title-right"
                :src="require('../../assets/image/tag.png')"
              /><span>年龄分布</span>
            </div>
            <div id="age" style="width: 100%; height: 100%"></div>
          </div>
        </div>
        <div id="s-map" class="item-foot-right" style="position: relative;">
          <div style="padding: 10px 15px 0 15px">
            <img
              style="padding-right: 10px"
              class="title-right"
              :src="require('../../assets/image/tag.png')"
            /><span>地域分布</span>
          </div>
          <div class="ranking">
            <span style="padding:10px">排名</span>
            <span>城市</span>
          </div>
           <div style="position: absolute;right: 35px;top: 50px;">
            <span>占比</span>
          </div>
          <div id="atlas" style="width: 100%; height: 88%"></div>
          <div class="paging-box pages-center">
            <el-pagination
              layout="prev, pager, next"
              :total="region_data.length"
              :current-page.sync="page"
              :page-size="pageSize"
              @current-change="pageChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import china from "@/china.json";
import { getReviewData, getGoodsTrend } from "@/utils/apis";
export default {
  data() {
    return {
      sex_mam: 0,
      sex_woman: 0,
      barrage_num:'',
      like_num:'',
      chartsTitleStyle: {
        color: "#fff",
        fontSize: 14,
        fontWeight: 400,
      },
      commodityList: [],
      commodity: '',
      region_data: [],
      page: 1,
      pageSize: 1,
      synthesize:true,
      interaction:false,
      temperature:false,
      dataTitleList:[
        {
          id:0,
          name:'综合数据'
        },
         {
          id:1,
          name:'互动数据'
        },
         {
          id:2,
          name:'商品热度'
        },
      ],
      currentIndex:0,

      //累计观看人数
      in_online_number: "",
      //人数峰值
      highest_online_total_number: "",
      //平均在线人数
      ave_online_total_number: "",
      //本场销量
      deal_number: "",
      //本场销售额
      sales: "",
      //地图
      region_data: [],
    };
  },
  mounted() {
    this.getDate();
    // this.getheatData();
    this.heatFun();
    this.natRegionChart();
  },
  methods: {
    //获取商品热度数据
    getheatData() {
      let params = {
        train_id: this.$route.query.train_id,
        goods_id: this.commodity,
        // train_id: JSON.parse(localStorage.getItem("train_id")),
        user_id:this.$route.query.user_id,
      };
      getGoodsTrend(params)
        .then((res) => {
          if (res.code == 200) {
            let create_time = [];
            let sales = [];
            for (let i = 0; i < res.data.length; i++) {
              create_time.push(this.getHMS(res.data[i].start_time));
              sales.push(parseFloat(res.data[i].sales));
            }
            this.heatFun(create_time, sales);
          }
        })
        .catch((err) => {
          console.error("err", err);
        });
    },
    //返回
     back(){
        this.$router.go(-1);//返回上一层
    },

    convertKey(arr, key) {
      let newArr = [];
      arr.forEach((item, index) => {
        let newObj = {};
        for (var i = 0; i < key.length; i++) {
          newObj[key[i]] = item[Object.keys(item)[i]];
        }
        newArr.push(newObj);
      });
      return newArr;
    },
     selfAdaption(Charts) {
          window.addEventListener("resize", function () {
              if (Charts) {  // 防止拉动窗口时，resize报错
                  Charts.resize();
              }
          });
      },

      dataTitleFun(index){
        this.currentIndex = index
        if(this.currentIndex ==0){
          this.synthesize =true
          this.interaction =false
          this.temperature =false
           this.$nextTick(() => {
            this.$echarts.init(document.getElementById("sellGoods")).resize()
          }) 
     
        }else if(this.currentIndex ==1){
          this.synthesize =false
          this.interaction =true
          this.temperature =false
          this.$nextTick(() => {
          this.$echarts.init(
            document.getElementById("tendency")
            ).resize()
          }) 

        }else if(this.currentIndex ==2){
          this.synthesize =false
          this.interaction =false
          this.temperature =true
          this.$nextTick(() => {
            this.$echarts.init(document.getElementById("heat")).resize()
          }) 
        }
        // console.log(index)
      },
       //秒转时分秒
            getHMS(time) {
                const hour = parseInt(time / 3600) < 10 ? '0' + parseInt(time / 3600) : parseInt(time / 3600)
                const min = parseInt(time % 3600 / 60) < 10 ? '0' + parseInt(time % 3600 / 60) : parseInt(time % 3600 / 60)
                const sec = parseInt(time % 3600 % 60) < 10 ? '0' + parseInt(time % 3600 % 60) : parseInt(time % 3600 % 60)
                return hour + ':' + min + ':' + sec
            },

    //获取数据
    getDate() {
      let params = {
        train_id: this.$route.query.train_id,
        // train_id: JSON.parse(localStorage.getItem("train_id")),
        user_id: this.$route.query.user_id,
      };
      getReviewData(params)
        .then((res) => {
          if (res.code == 200) {
            // console.log(res.data,'987')
            this.in_online_number = res.data.in_online_number; //累计观看人数
            this.highest_online_total_number =
              res.data.highest_online_total_number; //人数峰值
            this.ave_online_total_number = Math.floor(res.data.ave_online_total_number); //平均在线人数
            this.deal_number = res.data.deal_number; //本场销量
            this.barrage_num = res.data.barrage_num //总弹幕数
            this.like_num = res.data.like_num //总点赞数
            this.sales = res.data.sales; //本场销售额
            this.sex_mam = parseInt(res.data.sex_data.man); //性别分布
            this.sex_woman = parseInt(res.data.sex_data.woman); //性别分布
            this.commodityList = res.data.goods_box; //商品列表
            this.commodity = this.commodityList[0].goods_id
            this.getheatData()
            for (let x = 0; x < res.data.region_data.length; x++) {
              this.region_data.push(
                this.convertKey(res.data.region_data[x], [
                  "city_id",
                  "name",
                  "type",
                  "value",
                ])
              );
            }


            let age_data = []; //年龄分布数据
            for (let i = 0; i < res.data.age_data.length; i++) {
              age_data.push({
                name: res.data.age_data[i].name,
                value: res.data.age_data[i].proportion,
              });
            }

            //人气带货数据
            //在线观看人数
            let number = [];
            //本场销量
            let deal_number = [];
            //本场销售额
            let sales = [];
            //观众互动趋势
            //本场弹幕
            let barrage_num = [];
            //本场点赞
            let like = [];
            //X轴 时间
            let create_time = [];
              //销售额图表
            let sum_sales = []; //销售额Y轴
            let x_str = []; //销售额时间X轴
            for (let i = 0; i < res.data.list.length; i++) {
              number.push(res.data.list[i].online_total_number);
              deal_number.push(res.data.list[i].deal_number);
              sales.push(parseFloat(res.data.list[i].sales));
              barrage_num.push(res.data.list[i].barrage_num);
              like.push(res.data.list[i].like);
              create_time.push(this.getHMS(res.data.list[i].start_time));
              sum_sales.push(res.data.list[i].sales);
               x_str.push(this.getHMS(res.data.list[i].start_time));
            }
          
            // console.log(number,'96666666')
            number.unshift("在线观看人数");
            deal_number.unshift("本场销量");
            sales.unshift("本场销售额");
            barrage_num.unshift("本场弹幕");
            like.unshift("本场点赞");
            create_time.unshift("product");
          
            // for (let i = 0; i < res.data.list.sales.length; i++) {
             
            //   x_str.push(res.data.sales_list[i].x_str);
            // }

            this.$nextTick(() => {
              this.sellGoodsFun(number, deal_number, sales, create_time);
              this.tendencyFun(barrage_num, like, create_time);
              // this.saleFun(sum_sales, x_str);
              this.ageChart(age_data);
              this.natRegionChart(this.region_data[0]);
            });
          }
        })
        .catch((err) => {
          console.error("err", err);
        });
    },

    toggleClass() {
      this.getheatData();
    },
    //人气带货数据
    sellGoodsFun(number, deal_number, sales, create_time) {
      // console.log(create_time,'321')
      let myChart = this.$echarts.init(document.getElementById("sellGoods"));
      let option = {
        color: ["#201bca", "#AD2B5C", "#b47413"],
        legend: {
           icon: "none",
           left:'-30px',
          // top: "0%",
          // left:'-30px',
          // icon: "none",
          // itemWidth: 25,
          // itemHeight: 10,
          // itemGap: 0,
          // textStyle: {
          //   color: "#fff",
          //   fontSize: 16,
          //   borderType : 'solid',
          //   borderColor: '#fff',
          //   borderWidth: 1,
          //   padding:[15,20],
          //   inactiveColor:"#ccc",
          //   borderRadius: 30
          // },
          data:[
            {
              name:'在线观看人数',
              textStyle: {
                fontSize: 16,
                color: '#4b45ff',
                borderType : 'solid',
                borderColor: '#4b45ff',
                borderWidth: 1,
                padding:[15,20],
                inactiveColor:"#ccc",
                borderRadius: 30
                }
            },
             {
              name:'本场销量',
              textStyle: {
                  color: '#AD2b5c',
                  fontSize: 16,
                  borderType : 'solid',
                  borderColor: '#AD2b5c',
                  borderWidth: 1,
                  padding:[15,20],
                  inactiveColor:"#ccc",
                  borderRadius: 30
                }
            },
             {
              name:'本场销售额',
              textStyle: {
                color: '#c67f14',
                 fontSize: 16,
                  borderType : 'solid',
                  borderColor: '#c67f14',
                  borderWidth: 1,
                  padding:[15,20],
                  inactiveColor:"#ccc",
                  borderRadius: 30
                }
            }
          ]
          
        },
        
        
        dataZoom: [
              {
                  show: true,
                  height: 17,
                  realtime: true,
                  start: 0,
                  end: 50,
                  bottom:'5%'
              },
              {
                  type: 'inside',
                  height: 10,
                  realtime: true,
                  start: 0,
                  end: 50
              }
          ],
        tooltip: {
          trigger: "axis",
          showContent: true,
          // formatter(params) {
          //   let str = '';

          //   var dotHtml ='<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#201bca;"></span>'
          //   var dotHtml_two ='<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#AD2B5C;"></span>'
          //   var dotHtml_three ='<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#b47413;"></span>'
          //   for (let x = 1; x < params[0].dimensionNames.length; x ++) {
          //     if(params[0].dimensionNames[x] =='在线观看人数'){
          //       str += dotHtml + '' + params[0].dimensionNames[x] + '\xa0\xa0\xa0' + params[0].value[x] + "</br>"
          //     }else if(params[0].dimensionNames[x] =='本场销量'){
          //        str += dotHtml_two + '' + params[0].dimensionNames[x] + '\xa0\xa0\xa0' + params[0].value[x] + "</br>"
          //     }else if(params[0].dimensionNames[x] =='本场销售额'){
          //         str += dotHtml_three + '' + params[0].dimensionNames[x] + '\xa0\xa0\xa0' + params[0].value[x] + "</br>"
          //     }
          //   }
          //   return str
          // },
          backgroundColor: 'rgba(0, 0, 0, .6)',
          borderColor: 'rgba(0, 0, 0, .6)',
          textStyle: {
              color: '#fff',
              fontSize: 14,
              fontWeight: 400,
          },
        },
        dataset: {
          source: [create_time, number, deal_number, sales],
        },
        xAxis: {
          type: "category",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#2e317a", // 颜色
              width: 1, // 粗细
            },
          },
          axisLabel: {
            interval:0,  
            rotate:40,
            textStyle: {
              //改变刻度字体样式
              color: "#fff",
            },
          },
          boundaryGap: false,
        },
        yAxis: {
          gridIndex: 0,
          min: 0,
          // max: 140,

          splitNumber: 4,
          splitLine: {
            show: true,
            lineStyle: {
              color: "#14174B",
              // color: 'rgba(255,255,255,0.1)'
            },
          },
          axisLabel: {
            formatter: "{value} ",
        
            textStyle: {
              //改变刻度字体样式
              color: "#fff",
            },
          },
        },
        grid: { bottom:'25%', top: "20%", x: 80, x2: 60, y: 50, y2: 100 },

        series: [
          {
            showSymbol: false,
            type: "line",
            smooth: true,
            seriesLayoutBy: "row",
            emphasis: { focus: "series" },
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#201bca",
                },
              },
            },
          },

          {
            type: "line",
            smooth: true,
            showSymbol: false,
            seriesLayoutBy: "row",
            emphasis: { focus: "series" },
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#AD2B5C",
                },
              },
            },
          },
          {
            type: "line",
            smooth: true,
            showSymbol: false,
            seriesLayoutBy: "row",
            emphasis: { focus: "series" },
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#b47413",
                },
              },
            },
          },
        ],
      };

      window.addEventListener("resize", function () {
        myChart.resize();
      });
      myChart.setOption(option);
    },
    tendencyFun(barrage_num, like, create_time) {
      let tendencyChart = this.$echarts.init(
        document.getElementById("tendency")
      );
      let option = {
        color: ["#201bca", "#11ABB2"],
        legend: {
          top: "0px",
          left:'-30px',
          icon: "none",
          itemWidth: 25,
          itemHeight: 10,
          itemGap: 0,
          textStyle: {
            color: "#4b45ff",
            fontSize: 16,
             borderType : 'solid',
            borderColor: '#4b45ff',
            borderWidth: 1,
            padding:[15,20],
            inactiveColor:"#ccc",
            borderRadius: 30
          },
        },
          dataZoom: [
              {
                  show: true,
                  height: 17,
                  realtime: true,
                  start: 0,
                  end: 50,
                  bottom:'12%'
              },
              {
                  type: 'inside',
                  height: 10,
                  realtime: true,
                  start: 0,
                  end: 50
              }
          ],
        tooltip: {
          trigger: "axis",
          showContent: true,
          // formatter(params) {
          //   let str = '';
          //   for (let x = 1; x < params[0].dimensionNames.length; x ++) {
          //     str += params[0].marker + '' + params[0].dimensionNames[x] + '\xa0\xa0\xa0' + params[0].value[x] + "</br>"
          //   }
          //   return str
          // },
          backgroundColor: 'rgba(0, 0, 0, .6)',
          borderColor: 'rgba(0, 0, 0, .6)',
          textStyle: {
              color: '#fff',
              fontSize: 14,
              fontWeight: 400,
          },
        },
        dataset: {
          source: [create_time, barrage_num, like],
        },
        xAxis: {
          type: "category",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#2e317a", // 颜色
              width: 1, // 粗细
            },
          },
          axisLabel: {
            interval:0,  
            rotate:40,
            textStyle: {
              //改变刻度字体样式
              color: "#fff",
            },
          },
          boundaryGap: false,
        },
        yAxis: {
          gridIndex: 0,
          min: 0,
          // max: 140,
          splitNumber: 4,
          splitLine: {
            show: true,
            lineStyle: {
              color: "#14174B",
              // color: 'rgba(255,255,255,0.1)'
            },
          },
          axisLabel: {
            formatter: "{value} ",
            textStyle: {
              //改变刻度字体样式
              color: "#fff",
            },
          },
        },
        grid: {bottom:'30%', top: "20%", x: 80, x2: 60, y: 50, y2: 100 },

        series: [
          {
            showSymbol: false,
            type: "line",
            smooth: true,
            seriesLayoutBy: "row",
            emphasis: { focus: "series" },
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#201bca",
                },
              },
            },
          },
          // {
          //   type: "line",
          //   smooth: true,
          //   showSymbol: false,
          //   seriesLayoutBy: "row",
          //   emphasis: { focus: "series" },
          //   itemStyle: {
          //     normal: {
          //       lineStyle: {
          //         color: "#11ABB2",
          //       },
          //     },
          //   },
          // },
        ],
      };

      window.addEventListener("resize", function () {
        tendencyChart.resize();
      });
      tendencyChart.setOption(option);
    },
    heatFun(create_time, sales) { 
      let heatChart = this.$echarts.init(document.getElementById("heat"));
      let option = {
        tooltip: {
          trigger: "axis",
          showContent: true,
          backgroundColor: 'rgba(0, 0, 0, .6)',
          borderColor: 'rgba(0, 0, 0, .6)',
           textStyle: {
              color: '#fff',
              fontSize: 14,
              fontWeight: 400,
          },
        },

        legend: {
          data: ["销售额"],
          top: "0",
          icon: "none",
           left:'-30px',
          itemWidth: 25,
          itemHeight: 10,
          itemGap: 30,
          textStyle: {
            color: "#00f7ff",
            fontSize: 16,
              borderType : 'solid',
            borderColor: '#00f7ff',
            borderWidth: 1,
            padding:[15,20],
            inactiveColor:"#ccc",
            borderRadius: 30
          },
        },
        grid: {
          top: "20%",
          x: 80,
          x2: 60,
          y: 50,
          y2: 100,
        },
        xAxis: [
          {
            type: "category",
            data: create_time,
            axisTick: {
              alignWithLabel: true,
              show: true,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#2e317a", // 颜色
                width: 1, // 粗细
              },
            },
            axisLabel: {
              interval:0,  
              rotate:40,
              textStyle: {
                //改变刻度字体样式
                color: "#fff",
              },
            },
          },
        ],

        yAxis: [
          {
            type: "value",
            // min: 0,
            // max: 50,
            // interval: 10,
            axisLabel: {
              formatter: "{value} ",
              textStyle: {
                //改变刻度字体样式
                color: "#fff",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#14174B",
                // color: 'rgba(255,255,255,0.1)'
              },
            },
          },
          // {
          //   type: "value",
          //   min: 0,
          //   max: 50,
          //   interval: 10,
          //   axisLabel: {
          //     formatter: "{value} W",
          //     textStyle: {
          //       //改变刻度字体样式
          //       color: "#fff",
          //     },
          //   },
          //   splitLine: {
          //     show: true,
          //     lineStyle: {
          //       color: "#14174B",
          //       // color: 'rgba(255,255,255,0.1)'
          //     },
          //   },
          // },
        ],
        series: [
          // {
          //   name: "浏览量",
          //   type: "bar",
          //   barWidth: "20%",
          //   smooth: true,
          //   itemStyle: {
          //     normal: {
          //       color: "#4a45ff", //柱子的颜色
          //       barBorderRadius: [15, 15, 0, 0],
          //     },
          //   },
          //   data: [10, 20, 40, 10, 30, 40, 500],
          // },
          {
            name: "销售额",
            type: "bar",
            smooth: true,
            barWidth : 20,
            itemStyle: {
              normal: {
                 color: "#00e1f1", //柱子的颜色
                barBorderRadius: [15, 15, 0, 0],
              },
            },
            data: sales,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。

      window.addEventListener("resize", function () {
        heatChart.resize();
      });
      heatChart.setOption(option);
    },
    // saleFun(sum_sales, x_str) {
    //   console.log(sum_sales,'8888')
    //   let saleChart = this.$echarts.init(document.getElementById("sale"));

    //   let option = {
    //     tooltip: {
    //       trigger: "axis",
    //       showContent: true,
    //     },

    //     grid: {
    //       top: "10%",
    //       x: 80,
    //       x2: 80,
    //       y: 50,
    //       y2: 100,
    //     },
    //     xAxis: [
    //       {
    //         type: "category",
    //         data: x_str,
    //         axisTick: {
    //           alignWithLabel: true,
    //           show: true,
    //         },
    //         axisLine: {
    //           show: true,
    //           lineStyle: {
    //             color: "#2e317a", // 颜色
    //             width: 1, // 粗细
    //           },
    //         },
    //         axisLabel: {
    //           textStyle: {
    //             //改变刻度字体样式
    //             color: "#fff",
    //           },
    //           interval: 0,
    //           rotate: 40,
    //         },
    //       },
    //     ],

    //     yAxis: [
    //       {
    //         type: "value",
    //         // min: 0,
    //         // max: 60,
    //         // interval: 10,
    //         axisLabel: {
    //           formatter: "{value} W",
    //           textStyle: {
    //             //改变刻度字体样式
    //             color: "#fff",
    //           },
    //         },
    //         splitLine: {
    //           show: true,
    //           lineStyle: {
    //             color: "#14174B",
    //             // color: 'rgba(255,255,255,0.1)'
    //           },
    //         },
    //       },
    //     ],
    //     series: [
    //       {
    //         name: "销售额",
    //         type: "bar",
    //         barWidth: "20%",
    //         smooth: true,
    //         itemStyle: {
    //           normal: {
    //             color: "#4a45ff", //柱子的颜色
    //             barBorderRadius: [15, 15, 0, 0],
    //           },
    //         },
    //         data: sum_sales,
    //       },
    //     ],
    //   };
    //   // 使用刚指定的配置项和数据显示图表。
    //   window.addEventListener("resize", function () {
    //     saleChart.resize();
    //   });
    //   saleChart.setOption(option);
    // },
    //年龄分布
    ageChart(age_data) {
      // 网络分布图表
      let ageCharts = this.$echarts.init(document.getElementById("age"));
      // let data = networkData;
      let option = {
        title: {
          // text: "网络分布",
          textStyle: this.chartsTitleStyle,
          top: 26,
          left: 22,
        },
        series: [
          {
            type: "pie",
            radius: ["50%", "65%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            color: [
              "#4B45FF",
              "#12C4C4",
              "#51dc8e",
              "#FFA200",
              "#FF3E6C",
              "#1B1558",
            ],
            top: "10%",
            bottom: "21%",
            left: "16%",
            right: "16%",
            itemStyle: {
              borderRadius: 5,
              borderWidth: 5,
              borderColor: "#0C0E3F",
            },
            label: {
              show: true,
              position: "outside",
              fontSize: 14,
              fontWeight: 400,
              color: "#a4a4af",
              formatter: "{a|{c}%}\n{e|{b}}\n{hr|}",
              rich: {
                hr: {
                  backgroundColor: "auto",
                  borderRadius: 6,
                  width: 3,
                  height: 3,
                  padding: [6, 0, 0, -12],
                },
                a: {
                  padding: [-20, 10, -20, 15],
                },
                e: {
                  padding: [12, 10, -20, 15],
                },
              },
            },
            labelLine: {
              show: true,
              length: 15,
              length2: 26,
              lineStyle: {
                color: "#DDDDDD ",
              },
            },
            data: age_data,
          },
        ],
      };
      window.addEventListener("resize", function () {
        ageCharts.resize();
      });
      ageCharts.setOption(option);
    },
    pageChange() {
      // 全国区域分布图表分页
      this.natRegionChart(this.region_data[this.page - 1]);
    },
    natRegionChart(regionArr) {
      // 全国区域分布图表
      this.$echarts.registerMap("china", china);
      let natRegionCharts = this.$echarts.init(
        document.getElementById("atlas")
      );
      let img = require("../../assets/image/map1.png");
      let img2 = require("../../assets/image/map2.png");
      let img3 = require("../../assets/image/map3.png");
      let data = regionArr;
      let dataX = []; 
      let dataS = [];
      if (data != undefined) {
        dataX = data.sort((a, b) => {
          return a.value - b.value;
        });
        dataS = data.reverse();
      }

      let dataY = [];

      for (let x = 0; x < dataS.length; x++) {
        dataY.push(
          (this.page - 1) * this.pageSize * 10 +
            x +
            1 +
            "\xa0\xa0\xa0" +
            data[x].name
        );
      }

      // let pad = [-38, 0, 0,window.innerWidth - 800]

      let option = {
        title: {
          // text: "  排名    城市",
          // textStyle: {
          //   fontSize: 14,
          //   color: "rgba(255, 255, 255, .5)",
          // },
          // subtext: "{a|占比}",
          // subtextStyle: {
          //   rich: {
          //     a: {
          //       fontSize: 14,
          //       color: "rgba(255, 255, 255, .5)",
          //       padding: [-38, 0, 0,480],
          //     },
          //   },
          // },
          // top: 19,
          // right: 40,
        },
        visualMap: {
          type: "continuous",
          text: ["低", "高"],
          hoverLink: false,
          orient: "horizontal",
          itemWidth: 11,
          itemHeight: 84,
          left: 109,
          bottom: 0,
          inRange: {
            color: ["#4B45FF", "#191C51"],
          },
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
        },
        grid: {
          right: 100,
          top: 50,
          bottom: 25,
          width: "25%",
        },
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: [
          {
            type: "category",
            inverse: true,
            offset: 20,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0,
              margin: 85,
              textStyle: {
                align: "left",
                color: "#fff",
                fontSize: 14,
              },
              rich: {
                img: {
                  height: 20,
                  backgroundColor: { image: img },
                },
                img2: {
                  height: 20,
                  backgroundColor: { image: img2 },
                },
                img3: {
                  height: 20,
                  backgroundColor: { image: img3 },
                },
              },
              formatter: function (params) {  
                if (parseInt(params.slice(0, 2)) == 1) {
                  return ["{img|}" + " " + params.slice(1)].join("\n");
                } else if (parseInt(params.slice(0, 2)) == 2) {
                  return ["{img2|}" + " " + params.slice(1)].join("\n");
                } else if (parseInt(params.slice(0, 2)) == 3) {
                  return ["{img3|}" + " " + params.slice(1)].join("\n");
                } else if (parseInt(params.slice(0, 2)) > 9) {
                  return [
                    parseInt(params.slice(0, 2)) + " " + params.slice(2),
                  ].join("\n");
                } else {
                  return [
                    parseInt(params.slice(0, 2)) + "    " + params.slice(2),
                  ].join("\n");
                }
              },
            },
            data: dataY,
          },
          {
            type: "category",
            axisLine: "none",
            inverse: true,
            show: true,
            offset: 20,
            axisLabel: {
              textStyle: {
                color: "#fff",
                fontSize: 14,
              },
              formatter: "{value}%",
            },
            data: dataX,
          },
        ],
        geo: {
          show: true,
          map: "china",
          silent: true,
          top: 120,
          left: "9.4%",
          // right: '64%',
          bottom: 120,
          itemStyle: {
            normal: {
              borderColor: "rgba(255, 255, 255, 0.2)",
              borderWidth: 1,
              areaColor: "#191C51",
            },
          },
          regions: [
            {
              name: "南海诸岛",
              value: 0,
              itemStyle: {
                normal: {
                  opacity: 0,
                  label: {
                    show: false,
                  },
                },
              },
            },
          ],
        },
        series: [
          {
            name: "",
            type: "map",
            map: "china",
            geoIndex: 0,
            data: regionArr,
          },
          {
            name: "",
            type: "bar",
            barWidth: 10,
            barGap: 30,
            itemStyle: {
              normal: {
                color: "#4B45FF",
                borderRadius: 5,
              },
            },
            data: data,
          },
        ],
      };
      natRegionCharts.setOption(option);
      this.selfAdaption(natRegionCharts)
    },
  },
};
</script>

<style lang='scss' scoped>

::v-deep .item-left {
  .el-input__inner {
    color: #ffffff !important;
    background-color: #0c0e3f !important;
    border: 1px solid transparent;
    &::placeholder {
      color: #ffffff;
      opacity: 0.2;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .el-input__inner:focus {
    border: 1px solid #4b45ff;
  }

  .el-icon-arrow-up:before {
    content: "\e790";
    color: #fff;
    opacity: 0.5;
    width: 12px;
    height: 7px;
  }
  .el-select-dropdown {
    height: auto;
    border: 1px solid transparent;
    background-color: #0c0e3f;
  }
  .el-scrollbar {
    height: auto;
  }
  .el-scrollbar__wrap {
    margin-bottom: 0 !important;
  }
  .el-select-dropdown__item {
    height: 36px;
    margin-top: 9px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
  }
  .el-select-dropdown__item:hover {
    background-color: #262963;
  }
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background-color: #0c0e3f;
  }
  .el-select-dropdown__item.selected {
    background-color: #262963;
  }
}

.audience-source-sex {
  position: relative;
  height: 100px;
  ::v-deep .el-progress--circle .el-progress__text,
  .el-progress--dashboard .el-progress__text {
    color: white;
  }

  .man-box {
    position: absolute;
    left: 4%;
    top: 50px;
    .iconfont {
      position: absolute;
      right: 25%;
      top: 35%;
    }
    ::v-deep .el-progress--circle .el-progress__text,
    .el-progress--dashboard .el-progress__text {
      position: absolute;
      left: 60%;
      top: 90%;
      color: white;
    }
  }
  .el-progress {
    ::v-deep path:first-child {
      stroke: rgba(255, 255, 255, 0.1);
    }
  }

  .female-box {
    position: absolute;
    left: 45%;
    top: 50px;
    .iconfont {
      position: absolute;
      left: 63%;
      top: 35%;
    }
    ::v-deep .el-progress--circle .el-progress__text,
    .el-progress--dashboard .el-progress__text {
      position: absolute;
      left: 60%;
      top: 10%;
      color: white;
    }
  }
  // .line {
  //   border-bottom: 1px dashed #3f52a2;
  //   width: 500px;
  //   position: absolute;
  //   left: 41.5%;
  //   margin-left: -200px;
  //   top: 110px;
  // }
}
.exam-content {
  height: 100%;
  width: 100%;
  background: #070932;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;

    .el-scrollbar__view {
      height: 100%;
    }
  }

  .main-content {
    width: 500px;
    margin: 0 auto;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.live-data {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: 30px;
  // overflow: auto;
  .operate {
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
    .title-right {
      -moz-transform: matrix(-1, 0, 0, 1, 0, 0);
      -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
      -o-transform: matrix(-1, 0, 0, 1, 0, 0);
    }
    img {
      vertical-align: middle;
    }
  }
  .title-item {
    display: grid;
    grid-template-columns: repeat(5, 18.95%);
    grid-template-rows: 145px;
    grid-gap: 1.3%;
    width: 100%;
    height: auto;
    color: #fff;
    .title-one {
      background: url("../../assets/image/title-one.png") no-repeat;
      height: 100%;
      background-size: cover;
      border-radius: 15px 15px 0px 15px;
      display: table;
      width: 100%;
      .cont {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        padding-top: 35px;
        .title-num {
          font-size: 25px;
          font-weight: bold;
        }
      }
    }
    .title-two {
      background: url("../../assets/image/title-two.png") no-repeat;
      height: 100%;
      background-size: cover;
      border-radius: 15px 15px 0px 15px;
      display: table;
      width: 100%;
      .cont {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        padding-top: 35px;
        .title-num {
          font-size: 25px;
          font-weight: bold;
        }
      }
    }
    .title-three {
      background: url("../../assets/image/title-three.png") no-repeat;
      height: 100%;
      background-size: cover;
      border-radius: 15px 15px 0px 15px;
      display: table;
      width: 100%;
      .cont {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        padding-top: 35px;
        .title-num {
          font-size: 25px;
          font-weight: bold;
        }
      }
    }
    .title-four {
      background: url("../../assets/image/title-four.png") no-repeat;
      height: 100%;
      background-size: cover;
      border-radius: 15px 15px 0px 15px;
      display: table;
      width: 100%;
      .cont {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        padding-top: 35px;
        .title-num {
          font-size: 25px;
          font-weight: bold;
        }
      }
    }
    .title-five {
      background: url("../../assets/image/title-five.png") no-repeat;
      height: 100%;
      background-size: cover;
      border-radius: 15px 15px 0px 15px;
      display: table;
      width: 100%;
      .cont {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        padding-top: 35px;
        .title-num {
          margin-left: 10px;
          font-size: 25px;
          font-weight: bold;
        }
      }
    }
  }
  .dataTitle{
    color: #fff;
    ul{
      list-style-type: none;  
      display: flex;
      // background-color: #1d1f4f;
      padding: 0;
      li{
        padding: 15px 25px;
        cursor: pointer;
      }
    }
    .dataTitle_active{
     background-image: linear-gradient(to right, #55def0 , #3a33f6);
      border-radius: 30px;
    }
  }
  .title-item-two {
    // display: grid;
    // grid-template-columns: repeat(1, 100%);
    // grid-template-rows: 478px 478px 478px 478px; 
    // grid-gap: 1.3%;
    width: 100%;
    // height: 2050px;
    color: #fff;
    grid-row-gap: 3%;
    margin-top: 20px;
    margin-bottom: 20px;
    .item-left {
      background: #0c0e3f;
      height: 478px;
      margin-bottom: 10px;
    }
    .item-right {
      background-color: #0c0e3f;
       height: 478px;
        margin-bottom: 10px;
    }
  }
  .title-item-foot {
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    grid-template-rows: 690px;
    grid-gap: 1.3%;
    width: 100%;
    height: auto;
    color: #fff;
    grid-row-gap: 3%;
    margin-top: 45px;

    .item-foot-right {
      background: #0c0e3f;
    }
  }
}
.paging-box {
  width: 220px;
  height: 10px;
  background: #0c0e3f;
  margin-right: 98px;
  float: right;
}
.add-btn {
      color: #fff;
      background: #4c46fb;
      border: #4c46fb;
  }
::v-deep .el-pagination {
  .btn-prev {
    color: #999;
    background: #0c0e3f;
  }
  .btn-next {
    color: #999;
    background: #0c0e3f;
  }
}

::v-deep .pages-center .el-pager .number {
  color: #fff;
  background: #0c0e3f;
}
::v-deep .pages-center .el-pager .active {
  background: #2821fc;
  color: #fff;
}
::v-deep .pages-center .el-pager .btn-quicknext {
  color: #fff;
  background: #0c0e3f;
}
::v-deep .pages-center .el-pager .btn-quickprev {
  color: #fff;
  background: #0c0e3f;
}
.ranking{position: absolute;left: 58%;top: 50px;}
@media screen and (min-width:900px) and (max-width:1300px){
   #s-map .ranking{position: absolute;left: 48%;}
}
@media screen and (min-width:1300px) and (max-width:1400px){
   #s-map .ranking{position: absolute;left: 52%;}
}
@media screen and (min-width:1400px) and (max-width:1700px){
   #s-map .ranking{position: absolute;left: 55%;}
}
@media screen and (min-width:1700px) and (max-width:1900px){
   #s-map .ranking{position: absolute;left: 56%;}
}

</style>
